.script-gallery {
  padding: 40px 20px; /* Increased top and bottom padding */
  background-color: #F1F8FE; /* Lighter shade of blue */
  margin-top: 30px; /* Add spacing above the Script Gallery section */
  margin-bottom: 30px; /* Add spacing below the Script Gallery section */
}

.script-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.script-card {
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 12px;
  width: 200px;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.script-card:nth-child(5n+1) { background-color: #E0F7FA; }
.script-card:nth-child(5n+2) { background-color: #E8F5E9; }
.script-card:nth-child(5n+3) { background-color: #FFF3E0; }
.script-card:nth-child(5n+4) { background-color: #F3E5F5; }
.script-card:nth-child(5n+5) { background-color: #E1F5FE; }

.script-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.script-card h3 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
  line-height: 1.3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.script-content {
  font-size: 12px;
  color: #666;
  margin: 8px 0;
  padding: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  white-space: pre-wrap;
  word-break: break-word;
  max-height: 100px;
  overflow-y: auto;
}

.script-card button {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 8px;
}

.script-card button:hover {
  background-color: #1976D2;
}
