.work-group-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 300px;
  transition: transform 0.3s ease;
}

.work-group-card:hover {
  transform: translateY(-5px);
}

.card-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.card-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
