.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;

.buy-coffee {
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: #ffdd57;
  color: #000;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  z-index: 1001; /* Ensure button is on top */
}

header {
  margin-top: 80px; /* Add top margin to header */
}

.buy-coffee:hover {
  background-color: #ffc107;
}

.footer {
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: right;
  font-size: 1.2em;
  color: #555;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #f9f9f9;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000; /* Ensure footer is on top */
}

@media (max-width: 600px) {
  .buy-coffee {
    top: 10px; /* Adjust top spacing for mobile */
    left: 10px; /* Adjust left spacing for mobile */
  }
}

.footer span {
  margin-right: 10px;
}

.generated-script {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  max-height: 300px; /* Limit the height */
  overflow-y: auto; /* Add vertical scroll */
}

.generated-script pre {
  background-color: #e6e6e6;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

h1 {
  text-align: center;
}

.header-container {
  text-align: center;
  margin-top: 20px;
}

.shadow-header {
  font-size: 3em;
  font-weight: bold;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  transition: text-shadow 0.3s ease;
}

.shadow-header:hover {
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}
}

.subtitle {
  font-size: 1.2em;
  color: #555;
  margin-top: 10px;
  font-style: italic;
  font-weight: 300; /* Light weight for sleek look */
  animation: colorChange 5s infinite;
}

@keyframes colorChange {
  0% { color: #555; }
  25% { color: #888; }
  50% { color: #aaa; }
  75% { color: #ccc; }
  100% { color: #555; }
}

.file-upload {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.file-upload.dragging {
  background-color: #f0f0f0;
  border-color: #999;
}

.options-panel {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.options-panel label {
  display: block;
  margin-bottom: 10px;
}

button {
  background-color: #008080; /* Teal color */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: 30px 10px; /* Increased margin for more spacing */
  cursor: pointer;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.results-display {
  background-color: #e6f3ff;
  padding: 20px;
  border-radius: 10px;
}

.file-input-label {
  background-color: #008080; /* Teal color */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.file-list {
  list-style-type: none;
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.file-list li {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.file-list li:last-child {
  border-bottom: none;
}

.show-more-btn, .download-btn {
  background-color: #008080; /* Teal color */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 5px;
  cursor: pointer;
  border-radius: 5px;
}

.show-more-btn {
  background-color: #008080; /* Teal color */
}

.file-list {
  margin-bottom: 20px;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
}

.file-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.file-list button {
  margin-left: 10px;
}

.file-list input {
  flex-grow: 1;
  margin-right: 10px;
}

.file-list {
  margin: 20px 0;
  text-align: center;
}

.file-count {
  font-size: 1em; /* Smaller font size */
  font-weight: 600; /* Slightly less bold */
  color: #4a90e2; /* Stylish blue color */
  margin-bottom: 5px; /* Less margin */
  background: #f0f8ff; /* Light blue background */
  padding: 5px 10px; /* Padding for better spacing */
  border-radius: 5px; /* Rounded corners */
  display: inline-block; /* Inline block for better alignment */
}

.file-list h3 {
  margin-bottom: 10px;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
}

.file-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.file-list input {
  flex: 1;
  margin-right: 10px;
  padding: 5px;
}

.file-list button {
  margin-left: 5px;
}

.load-more {
  background-color: #008080; /* Teal color */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-content button {
  background-color: #008080; /* Teal color */
}

.sample-output {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 20px;
}
.work-groups {
  padding: 20px;
  background-color: #f9f9f9;
}

.work-group-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.work-group-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 300px;
  transition: transform 0.3s ease;
}

.work-group-card:hover {
  transform: translateY(-5px);
}

.card-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.card-icon {
  width: 100px;  /* Increased from 40px */
  height: 100px; /* Increased from 40px */
  margin-right: 10px;
  object-fit: contain; /* This ensures the entire icon is visible */
}

.faq-section {
  margin-top: 20px;
}

.faq-item {
  margin-bottom: 10px;
}

.faq-item h3 {
  margin: 0;
  font-size: 1.1em;
}

.faq-item p {
  margin: 5px 0 0 0;
}
.footer-text {
  font-size: 0.8em; /* Adjust the size as needed */
}
